import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Table
} from "react-bootstrap"; 

// import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import {apiUrl} from'../Config/Config';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { lang } from '../Helpers/lang';
const withRouter = WrappedComponent => props => {
    const params = useParams();
    const  navigate = useNavigate();
    // etc... other react-router-dom v6 hooks
    
    return (
      <WrappedComponent
      {...props}
      params={params}
      navigate ={navigate}
      // etc...
      />
    );
    };
class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      categorylist:'',
      activePage:1,
      sno_index:0,
    };
    


    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    

    componentDidMount() {
      this.categorylistload();
   }
   categorylistload = (postobject='') =>{
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
    if(postobject!=''){
      var postobject =postobject;
    }else{
      var postobject = {
        activePage: 1,
        search_all: '',
        admin_id: admin_id,
        loggedas: loggedas
        };  
    }
      
    
   

    axios.post(apiUrl+"guidesubcategory/list",qs.stringify(postobject)).then(res => {
      if(res.data.status === 'success'){
       
          this.setState({  categorylist: res.data.categorylist, totalRecord: res.data.records_count}, function() {
          console.log('afa');
            this.categorylist();
          });

      }
    }); 
   }
   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});
     var sno_index = pageNumber - 1;
     this.setState({sno_index: sno_index});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id,
       loggedas:loggedas
     };
    //  this.props.getcategorylist(qs.stringify(postobject))
    this.categorylistload(postobject)

   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  this.categorylistload(postObject)
 

} 


//    deletecategory(id){
//     var qs = require('qs');
//     var postObject = {
//       admin_id : localStorage.getItem("admin_id"),
//       id  :id
//     };

//   axios.post(apiUrl+"guidesubcategory/delete",qs.stringify(postObject)).then(res => {
//     if(res.data.status === 'success'){       
//           window.location.reload(); 
//     }
//   }); 
// }



activecategory(){
  
  let stat = ''
  if(this.state.selectedStatus ==='inactive'){
    stat = "I";
  }else{
    stat = "A";
  }  
          var qs = require('qs');
          var postObject = {
            admin_id : localStorage.getItem("admin_id"),
            id  :this.state.selectid,
            status: stat
          };
  
          if(stat == "I"){
            axios.post(apiUrl+"guidesubcategory/checkBeforeChange",qs.stringify(postObject)).then(res => {
              if(res.data.status === 'success'){
                this.change_status(postObject);
              }else{
                $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
              
                setTimeout(
                function() {
                  $('.success_message').html('');
                }
                .bind(this),
                3000
                );
              }
            });
          }else{
            this.change_status();
          }
  
        // this.props.getactivecategory(qs.stringify(postObject));
        
  }
  
  change_status(){
    
  let stat = ''
  if(this.state.selectedStatus ==='inactive'){
    stat = "I";
  }else{
    stat = "A";
  }  
  
      var qs = require('qs');
      var postObject = {
        admin_id : localStorage.getItem("admin_id"),
        id  :this.state.selectid,
    status: stat
      };
      axios.post(apiUrl+"guidesubcategory/change_status",qs.stringify(postObject)).then(res1 => {      
        if(res1.data.status === 'success'){
          
          this.categorylistload()

            $('.success_message').html('<div class="status_sucess"><h3> '+res1.data.message+' </h3></div>');
            setTimeout(
              function() {
             
                $('.success_message').html('');
                 
              }
              .bind(this),
              3000
              );
              
        }
      }); 
    
  }

categorylist() {
    var categorylist = this.state.categorylist;
    var sno_index = this.state.sno_index;
    if (categorylist != "undefined" && categorylist != null) {
      console.log(categorylist);
    
      if (categorylist.length > 0) {

        if (Object.keys(categorylist).length > 0) {
          const helperlistDetails = categorylist.map(
            (categorylist, Index) => {
              if(sno_index > 0){ Index = (sno_index * 10) + Index; } 
              let sno = Index+1;
              

               if(categorylist.status == 'A'){
                var status = 'active';
              }else{
                  status = 'inactive';
              }
             
              return (
                <tr key={categorylist.id}>
      					<td>{sno}</td>
      					<td>{categorylist.cate_name}</td>
      					<td>{categorylist.sub_cate_name}</td>
                
                <td>{lang.Capitalize(status)}</td>
                <td className="actiongroup" >
                    
                    
                     {/* <Link to={{ pathname: '/guide-view/'+categorylist.id}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link> */}
                    <Link to={{ pathname: '/guidesubcategory-edit/'+categorylist.id}} className="" title="Edit">  <i className="ft-edit-3" aria-hidden="true"></i></Link>
                    {(status == 'Active'?<Link onClick={this.confirmPopup.bind(this, categorylist.id, status)} className="" title="Status"><i className="ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.confirmPopup.bind(this, categorylist.id, status)} className="" title="Status"><i className="ft-lock" aria-hidden="true"></i></Link>)}      
                    <Link onClick={this.confirmPopup.bind(this,categorylist.id,'delete')} className="" title="Delete"><i className="ft-trash-2" aria-hidden="true"></i></Link>
                   

                     {/* <Link onClick={this.deletecategory.bind(this,categorylist.id)} className="" title="Duplicate"><i className="ft-trash-2" aria-hidden="true"></i></Link> */}
                    
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="11" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="11" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  
  confirmPopup = (id, status) => {
    var status = status;
    if(status == 'inactive'){
      status = "active";
    }else if(status == "active"){
      status = "inactive";
    }else{
        status = "delete"
    }
    this.setState({selectid: id, selectedStatus: status})	
    $('.confirm-action').addClass('show');		
  }
  deleteguide(){
   
    var qs = require('qs');
    var postObject = {
      admin_id : localStorage.getItem("admin_id"),
      id  :this.state.selectid,
     
    };
  
  axios.post(apiUrl+"guidesubcategory/delete",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){
      var postobject1 = {
        activePage: this.state.activePage,
        search_all: '',
        admin_id : localStorage.getItem("admin_id")
      };
      this.categorylistload(postobject1)
  
     
      $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
      setTimeout(
        function() {
       
          $('.success_message').html('');
           
        }
        .bind(this),
        3000
        );
        
  }
  }); 
  }
  
  Confirmstatus = (confirmstatus) => {
    if(confirmstatus == 'yes'){

      if(this.state.selectedStatus=='delete'){
        this.deleteguide();
      }else {
        this.activecategory();
      }
     
      $('.confirm-action').removeClass('show');
    }else{
      $('.confirm-action').removeClass('show');	
    }
      
  }

  render() {

    const {} = this.state;
	  var current = this;
    var admin_id = localStorage.getItem('admin_id');
   let catImg = '';
  let preview = '';
    return (
      <div className="wrapper"> 
 <div className="confirm-action">
			<p>Are you sure! you want to {this.state.selectedStatus} the category?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
    <Header />
    <MenuSidebar currentpage="GuideSubCategory" /> 
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
   
         <div className="car-listing-wrap leaa-admin-head card">
            <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                  <h3>Guide Sub Category</h3>
                </div>
               

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
                 <div className="add_button">  
                    <a className="" href='/GuideSubCategory-add'>Add</a>
                </div>
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			            <th>Category</th>
			            <th>Sub Category</th>
                  <th>Status</th>
                  <th style={{width:"100px"}}>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.categorylist()}</tbody>
                </Table>
			        	</div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}
export default (withRouter(List));