import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import $ from 'jquery';
import { lang } from '../Helpers/lang';
import Select from 'react-select';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { baseUrl } from '../Config/Config';


const options = [
  { value: 'A', label: 'Active' },
  { value: 'I', label: 'In Active' },
];
 

class List extends Component {
  
  constructor(props)
  {
     super(props); 
      this.state = {
          storelist:'',
          activePage: 1,
          totalRecord: '',
          search_all:'',
          selectedStatus:'',
          selectid:'',
          data_status:'',
          storeIds: [],
         
      };
    

      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);

    }

   
   componentDidMount() {
    this.listloadedData()
   }


  listloadedData(postobject='') {
  var qs = require('qs');
   if(postobject!=''){
    var postobject = postobject
   }else{
    var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: localStorage.getItem('admin_id')  
      };
   }
  
 axios.post(apiUrl+"medlist/storelist",qs.stringify(postobject)).then(res => {
   
   if(res.data.status === 'success'){
      
    this.setState({
      storelist: res.data.storelist,
      totalRecord: res.data.records_count,
      data_status: res.data.status
    }, () => {
      this.storelist(); // Assuming this is a function to update the UI based on the new state
      const storeIds = res.data.storelist
      // Update storeIds based on merchant_status 'A'
      //const storeIds = res.data.storelist
        .filter(store => store.merchant_status === 'A')
        .map(store => store.merchant_list_id);
      this.setState({ storeIds: storeIds });

     const ids = storeIds.filter((list) => list.merchant_status ===  'A');
     console.log(ids)
    });
   } else {
      this.setState({  data_status: res.data.status}, function() {
      this.storelist();
    });
   }
 }); 
}

  
 
 
  handlePageChange(pageNumber) {
   
    this.setState({activePage: pageNumber});
    var qs = require('qs');
    var postobject = {
      activePage: pageNumber,
      search_all: this.state.search_all,
      admin_id: localStorage.getItem('admin_id')  
    };
   this.listloadedData(postobject)
  }

  handleInputChange(event) {
  const {name, value} = event.target;  
  var postObject = {             
    activePage   : this.state.activePage,
    search_all : value,
    admin_id: localStorage.getItem('admin_id')           
  };    
  this.setState({
    [name]: value
  },function(){
    this.listloadedData(postObject)
  });
    

}
handleCheckstore = (id) =>{

      var storeArr = [...this.state.storeIds];
      const index = storeArr.findIndex(list => list === id);
      if(index > -1) {
        
        storeArr = [...storeArr.slice(0, index), ...storeArr.slice(index + 1)]
      } else {
        storeArr.push(id);
      }

      this.setState({storeIds: storeArr});
   
     
  }

 handleFormSubmit = () => {
      
        this.setState({Loading:true});
        var qs = require('qs');
        const formPayload = this.state;
         
        var postObject = {             
          activePage   : this.state.activePage,
          search_all : this.state.search_all,
          admin_id: localStorage.getItem('admin_id')           
        };
        this.listloadedData(postObject)
      
  } 

   componentWillReceiveProps(Props){
   }



activateuser(){
  
	let stat = ''
		if(this.state.selectedStatus ==='inactive'){
			stat = "I";
		}else{
			stat = "A";
		}  

        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          user_id  :this.state.selectid,
		  user_status: stat
        };
        axios.post(apiUrl+"user/change_status",qs.stringify(postObject)).then(res => {      
          if(res.data.status === 'success'){
              this.listloadedData()
              $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
              setTimeout(
                function() {
               
                  $('.success_message').html('');
                   
                }
                .bind(this),
                3000
                );
                
          }
        }); 
}


confirmPopup = (id, status) => {
  var status = status;
  if(status == 'inactive'){
    status = "active";
  }else{
    status = "inactive";
  }
  this.setState({selectid: id, selectedStatus: status})	
  $('.confirm-action').addClass('show');		
}

Confirmstatus = (confirmstatus) => {
  if(confirmstatus == 'yes'){
    this.activateuser();
    $('.confirm-action').removeClass('show');
  }else{
    $('.confirm-action').removeClass('show');	
  }
    
}
exportdata(){
	let stat = '';		
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id")
        };
        axios.post(apiUrl+"user/export",qs.stringify(postObject)).then(res => {      
          if(res.data.status === 'success'){
            var url = res.data.url;
            var cleanurl = url.replace(/([^:]\/)\/+/g, "$1");
            window.open(cleanurl, '_blank', 'noreferrer');
            // window.location.reload();
          }
        }); 
      
}
  storelist() {

    var storelist = this.state.storelist;
    var data_status = this.state.data_status;
    
    if (storelist != "undefined" && storelist != null) {
      var status = storelist.status === 'A' ? 'active' : 'inactive';
      if (data_status == "success") {
        if (Object.keys(storelist).length > 0) {
          const userlistDetails = storelist.map(
            (storelist, Index) => {
              let sno = Index+1;
              var status = storelist.merchant_status === 'A' ? 'active' : 'inactive';
              
              return (
                <tr key={storelist.id}>
                    <td> 
                    <input
                      type="checkbox"
                      checked={this.state.storeIds.includes(storelist.merchant_list_id)}
                      onChange={() => this.handleCheckstore(storelist.merchant_list_id)}
                      key={storelist.merchant_list_id} 
                    />
                      {storelist.merchant} </td>
                  <td>{lang.Capitalize(status)}</td>
                </tr>
              );
            }
          );
          return userlistDetails;
        } else {
          return (
            <tr>
              <td colSpan="9" className="v-align-nr">
                No Result
              </td>
            </tr>
          );
        }
      }  else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    } else {
      return (
        <tr>
          <td colspan="11" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  activateSelected = () => {
    // Logic to activate selected rows
    const selectedIds = this.state.storeIds;
    var status = 'A';
   
    var qs = require('qs');
    var postObject = {
      admin_id : localStorage.getItem("admin_id"),
      selectedstoreIds: selectedIds,
      status:status,
    };
    axios.post(apiUrl+"medlist/changestore_status",qs.stringify(postObject)).then(res => {      
      if(res.data.status === 'success'){
        $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
        setTimeout(
          function() {
         
            $('.success_message').html('');
             
          }
          .bind(this),
          3000
          );
        // window.location.reload();
      }
    }); 
    // Send selectedIds to the API to update the database
  };
  
  deactivateSelected = () => {
   
    const selectedIds = this.state.storeIds;
    
    var status = 'A';
   
    var qs = require('qs');
    var postObject = {
      admin_id : localStorage.getItem("admin_id"),
      selectedstoreIds: selectedIds,
      status:status,
    };
    axios.post(apiUrl+"medlist/changestore_status",qs.stringify(postObject)).then(res => {      
      if(res.data.status === 'success'){
       
        $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
        setTimeout(
          function() {
         
            $('.success_message').html('');
             
          }
          .bind(this),
          3000
          );
        // window.location.reload();
      }
    }); 
    // Send selectedIds to the API to update the database
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    
    var qs = require('qs');
    var postObject = {
      activePage: 1,
      admin_id : localStorage.getItem("admin_id"),
      status:selectedOption.value,
    };
    axios.post(apiUrl+"medlist/store_status",qs.stringify(postObject)).then(res => {      
      this.setState({
        storelist: res.data.storelist,
        totalRecord: res.data.records_count,
        data_status: res.data.status
      }, () => {
        this.storelist();
      });
    }); 

  }


  render() {
    return (
      <div className="wrapper"> 
 <div className="confirm-action">
			<p>Are you sure! you want to {this.state.selectedStatus} the user?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
    <Header />
    <MenuSidebar currentpage="medstores" />  

  
  <div className="content"> 
    <div className="content-wrapper">
    <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
    <div className="success_message"></div>
        <div className="listing-header align-items-center"> 
          <div className="title p-0">
            <h3 className='m-0'>Med Stores</h3>
          </div>
            <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
          </form>
                </div>
                <div className="form-group">					
					 {/* <label>Appointment Duration<span class="required" style={{ color: "red" }} > </span></label> */}
           <Select 
							  options={options}  
							  value={this.state.selectedOption}
							  onChange={this.handleChange} // Handler for change events
                placeholder="Select Status" // Optional placeholder text
						/>	
					</div>
          <div className="add_button"> 
          <button className='btn btn-danger deactive-btn' onClick={this.deactivateSelected}>Deactivate</button>
          <button className='btn btn-danger active ' onClick={this.activateSelected}>Activate</button> 
          
          </div>
        </div>

        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Store Name</th>
			          <th>Status</th>
              </tr>
            </thead>
            <tbody>{this.storelist()}</tbody>
          </Table>
          <div className="text-center">
            {console.log(this.state.activePage,'this is working for page activedata', this.state.totalRecord)}
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
        </div>
                      
      </div>  
    </div>  
  </div>
    
    </div>
    );
  }
}

export default List;